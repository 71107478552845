import { useEffect } from 'react';
import useRequest from '@/websdk/utils/request';

export default (function (param) {
  const { exec, isRequestSuccessed } = useRequest({
    url: '',
    name: 'PUT_ADDITIVE',
    method: 'PUT',
    data: param,
  });

  useEffect(() => {
    if (param && param.id) {
      let url = `/api/additives/${param.id}`;
      exec(url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param]);

  return { exec, isRequestSuccessed };
});
