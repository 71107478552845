function setLang(code) {
  let config = JSON.parse(localStorage.getItem('websdk_config') || '{}');
  config.lang = code;
  localStorage.setItem('websdk_config', JSON.stringify(config));
}

function getLang() {
  let config = JSON.parse(localStorage.getItem('websdk_config') || '{}');
  if (config.lang) return config.lang;
  else return navigator.languages[0];
}

export { setLang, getLang };
