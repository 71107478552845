import useRequest from '@/websdk/utils/request';

export default (function (manual) {
  const url = '/api/moulds';

  const { data, exec } = useRequest({
    name: 'GET_MODULE_LIST',
    url,
    manual,
  });

  return [data || [], exec];
});
