import useRequest from '@/websdk/utils/request';

export default (function () {
  const { exec, isRequestSuccessed } = useRequest({
    url: '/api/workers',
    name: 'PUT_WORKER',
    method: 'PUT',
  });

  return [exec, isRequestSuccessed];
});
