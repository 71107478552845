import { useEffect } from 'react';
import useRequest from '@/websdk/utils/request';

export default (function (params) {
  const { exec, isRequestSuccessed } = useRequest({
    url: '',
    name: 'PUT_CRAFT',
    method: 'PUT',
  });

  useEffect(() => {
    if (params)
      exec({
        url: `/api/crafts/${params.id}`,
        data: params,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return isRequestSuccessed;
});
