const en_US = {
  FAILED: 'failed',
  REASON: 'Reason',
  '!': '!',
  ':': ':',
  PARAMETER_ERROR: 'Parameter error',
  NO_PERMISSION: 'No permission',
  RESOURCE_NOT_FOUND: 'Resource not found',
  SERVER_ERROR: 'Server error',
  GATEWAY_ERROR: 'Gateway error',
  SERVICE_UNAVAILABLE: 'Service unavailable',
  GATEWAY_TIMEOUT504: 'Gateway timeout',
};
export default en_US;
