import { EuiIcon } from '@elastic/eui';
import React, { useState, useEffect } from 'react';
import { useAccessControl, useLanguageList } from '@/server';
import { side_langIds } from '@/constant/lang_ids';
import {
  EuiCollapsibleNav,
  EuiHeaderSectionItemButton,
  EuiSideNav,
  EuiCollapsibleNavGroup,
  EuiListGroupItem,
} from '@elastic/eui';

export default (function () {
  const languages = useLanguageList({ ids: side_langIds, lang_codes: localStorage.lang });
  const pathName = window.location.pathname;
  if (localStorage.getItem('navIsDocked') === null) localStorage.setItem('navIsDocked', true);
  const navIsDocked = JSON.parse(String(localStorage.getItem('navIsDocked')));
  const [navIsOpen, setNavIsOpen] = useState(navIsDocked);
  const accessControl = useAccessControl('/api/crafts');
  const items = [
    {
      name: languages.DEVICE_MANAGE,
      id: 1,
      href: 'home.html',
      isSelected: pathName === '/home.html',
    },
    {
      name: languages.MODULE_MANAGE,
      id: 2,
      href: 'module.html',
      isSelected: pathName === '/module.html',
    },
    {
      name: languages.CUSTOMER_MANAGE,
      id: 3,
      href: 'customer.html',
      isSelected: pathName === '/customer.html',
    },
    {
      name: languages.PRODUCT_MANAGE,
      id: 4,
      href: 'product.html',
      isSelected: pathName === '/product.html',
    },
    {
      name: languages.MATERIAL_MANAGE,
      id: 5,
      href: 'material.html',
      isSelected: pathName === '/material.html',
    },
    {
      name: languages.PIGMENT_MANAGE,
      id: 6,
      href: 'pigment.html',
      isSelected: pathName === '/pigment.html',
    },
    {
      name: languages.CRAFT_MANAGE,
      id: 8,
      href: 'produce_craft.html',
      isSelected: pathName === '/produce_craft.html',
    },
    {
      name: languages.WORKER_MANAGE,
      id: 9,
      href: 'worker.html',
      isSelected: pathName === '/worker.html',
    },
    {
      name: languages.SCHEDULE_MANAGE,
      id: 10,
      href: 'produce_class.html',
      isSelected: pathName === '/produce_class.html',
    },
    {
      name: languages.CRAFT_ONLINE,
      id: 11,
      href: 'craft_online.html',
      isSelected: pathName === '/craft_online.html',
    },
    {
      name: languages.SYSTEM_SETTING,
      id: 12,
      href: 'system_setting.html',
      isSelected: pathName === '/system_setting.html',
    },
    {
      name: languages.ADDITIVE_MANAGE,
      id: 15,
      href: 'additive.html',
      isSelected: pathName === '/additive.html',
    },
    {
      name: languages.USER_ROLE_MANAGE,
      id: 13,
      href: 'user_role.html',
      isSelected: pathName === '/user_role.html',
    },
    {
      name: languages.WORKLINE_MANAGE,
      id: 14,
      href: 'worklines.html',
      isSelected: pathName === '/worklines.html',
    },
    {
      name: languages.CUSTOMER_ORDER_MANAGE,
      id: 16,
      href: 'customer_order.html',
      isSelected: pathName === '/customer_order.html',
    },
  ];
  const [sideNav, setSideNav] = useState([
    {
      name: '',
      id: 0,
      items: items,
    },
  ]);

  useEffect(() => {
    if (accessControl) {
      setSideNav([
        {
          name: '',
          id: 0,
          items: [
            ...items,
            {
              name: languages.CRAFT_PARAM_MANAGE,
              id: 7,
              href: 'craft.html',
              isSelected: pathName === '/craft.html',
            },
          ],
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessControl, languages]);

  return (
    <EuiCollapsibleNav
      id="iiws__sidebar"
      aria-label="Main navigation"
      isOpen={navIsOpen}
      isDocked={navIsDocked}
      button={
        <EuiHeaderSectionItemButton
          aria-label="Toggle main navigation"
          onClick={() => setNavIsOpen(!navIsOpen)}
        >
          <EuiIcon type={'menu'} size="l" color="white" aria-hidden="true" />
        </EuiHeaderSectionItemButton>
      }
      onClose={() => setNavIsOpen(false)}
    >
      <div style={{ position: 'relative', height: '100%' }}>
        <EuiSideNav isOpenOnMobile={true} items={sideNav} />
        <EuiCollapsibleNavGroup
          background="none"
          style={{ position: 'absolute', bottom: 64, left: 20 }}
        >
          <EuiListGroupItem
            size="xs"
            color="subdued"
            label={`${navIsDocked ? languages.AUTO_RETRACTION : languages.BERTH}${
              localStorage.lang === 'en-US' ? ' ' : ''
            }${languages.SIDEBAR}`}
            onClick={() => {
              localStorage.setItem('navIsDocked', JSON.stringify(!navIsDocked));
              window.location.reload();
            }}
            iconType={navIsDocked ? 'lock' : 'lockOpen'}
          />
        </EuiCollapsibleNavGroup>
      </div>
    </EuiCollapsibleNav>
  );
});
