import useRequest from '@/websdk/utils/request';

export default (function () {
  const url = '/api/assembly_line';

  const { data, exec } = useRequest({
    name: 'GET_WORK_LINE_LIST',
    url,
  });

  return [data || [], exec];
});
