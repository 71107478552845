import { useEffect } from 'react';
import useRequest from '@/websdk/utils/request';

export default function GetConfig(devId, key) {
  const { data, exec } = useRequest({
    url: '',
    name: 'GET_DEV_CONFIG',
    manual: true,
  });

  useEffect(() => {
    if (devId && key) {
      let url = `/api/v1/devices/${devId}/configs/${key}`;
      exec(url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devId, key]);

  return data;
}
