import useRequest from '@/websdk/utils/request';

export default (function (manual) {
  const { data, exec } = useRequest({
    name: 'GET_PRODUCT_LIST',
    url: '/api/products',
    manual,
  });

  return [data || [], exec];
});
