import React from 'react';
import sharedState from '../../utils/custom';

const initState = {
  list: [],
};

const actions = {
  setList: function (store, list) {
    store.setState({ list });
  },
};

const useStore = sharedState(React, initState, actions);

export default useStore;
