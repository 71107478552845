import useRequest from '@/websdk/utils/request';

export default (function (manual) {
  const { data, exec } = useRequest({
    name: 'GET_DEVICES_LIST',
    url: `/api/v1/devices`,
    manual,
  });

  return [data || [], exec];
});
