import useRequest from '@/websdk/utils/request';

export default (function () {
  const { exec, isRequestSuccessed } = useRequest({
    url: '/api/produce-crafts',
    name: 'POST_ONLINE_CRAFT',
    method: 'POST',
  });

  return [exec, isRequestSuccessed];
});
