import React, { useState } from 'react';

import { EuiBasicTable } from '@elastic/eui';
import Pagination from '@/classes/pagination';

export default function Table(props) {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const pagination = {
    pageIndex,
    pageSize,
    totalItemCount: props.items.length,
    pageSizeOptions: [10, 20, 50],
  };

  const [sortField, setSortField] = useState(props.sortField);
  const [sortDirection, setSortDirection] = useState('asc');

  const onTableChange = ({ page = {}, sort = {} }) => {
    if (JSON.stringify(page) !== '{}') {
      const { index: pageIndex, size: pageSize } = page;
      setPageIndex(pageIndex);
      setPageSize(pageSize);
    }
    if (JSON.stringify(sort) !== '{}') {
      const { field: sortField, direction: sortDirection } = sort;
      setSortField(sortField);
      setSortDirection(sortDirection);
    }
  };

  const items = props.sortField
    ? props.items.sort((a, b) => {
        if (a[props.sortField] && b[props.sortField]) {
          return sortDirection === 'asc'
            ? a[props.sortField].localeCompare(b[props.sortField])
            : b[props.sortField].localeCompare(a[props.sortField]);
        } else return 1;
      })
    : props.items;

  const pageChange = e => {
    setPageIndex(e.pageIndex);
    setPageSize(e.pageSize);
  };

  return (
    <div>
      <EuiBasicTable
        style={props.style}
        items={items.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize)}
        itemId={props.itemId}
        columns={props.columns}
        onChange={onTableChange}
        sorting={
          sortField
            ? {
                sort: {
                  field: sortField,
                  direction: sortDirection,
                },
              }
            : null
        }
      />
      <Pagination pagination={pagination} pageChange={pageChange} />
    </div>
  );
}

Table.defaultProps = {
  items: [],
};
