const zh_CN = {
  FAILED: '失败',
  REASON: '原因',
  '!': '！',
  ':': '：',
  PARAMETER_ERROR: '参数错误',
  NO_PERMISSION: '无权限',
  RESOURCE_NOT_FOUND: '资源未找到',
  SERVER_ERROR: '服务器错误',
  GATEWAY_ERROR: '网关错误',
  SERVICE_UNAVAILABLE: '服务不可用',
  GATEWAY_TIMEOUT504: '网关超时',
};
export default zh_CN;
