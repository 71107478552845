import useRequest from '@/websdk/utils/request';

export default (function (manual) {
  const { data } = useRequest({
    name: 'GET_WORKLINE_DEVICES',
    url: `/api/workline-devices`,
    manual,
  });

  return data || [];
});
