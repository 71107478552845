import useRequest from '@/websdk/utils/request';

export default (function () {
  const { exec, isRequestSuccessed } = useRequest({
    url: '/api/produce-classes',
    name: 'POST_PRODUCE_CLASSES',
    method: 'POST',
  });

  return [exec, isRequestSuccessed];
});
