const zh_TW = {
  FAILED: '失敗',
  REASON: '原因',
  '!': '！',
  ':': '：',
  PARAMETER_ERROR: '參數錯誤',
  NO_PERMISSION: '無權限',
  RESOURCE_NOT_FOUND: '資源未找到',
  SERVER_ERROR: '服務器錯誤',
  GATEWAY_ERROR: '網關錯誤',
  SERVICE_UNAVAILABLE: '服務不可用',
  GATEWAY_TIMEOUT504: '網關超時',
};
export default zh_TW;
