import React from 'react';
import sharedState from './custom';

const initState = {
  rst: 200,
  name: '',
  msg: '',
};

const actions = {
  change: function (store, rst, name, msg) {
    store.setState({ rst, name, msg });
  },
};

const useHttpMsg = sharedState(React, initState, actions);

export default useHttpMsg;
