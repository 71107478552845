const side_langIds = [
  'DEVICE_MANAGE',
  'MODULE_MANAGE',
  'CUSTOMER_MANAGE',
  'PRODUCT_MANAGE',
  'MATERIAL_MANAGE',
  'PIGMENT_MANAGE',
  'CRAFT_MANAGE',
  'WORKER_MANAGE',
  'SCHEDULE_MANAGE',
  'CRAFT_ONLINE',
  'SYSTEM_SETTING',
  'USER_ROLE_MANAGE',
  'WORKLINE_MANAGE',
  'ADDITIVE_MANAGE',
  'CRAFT_PARAM_MANAGE',
  'AUTO_RETRACTION',
  'BERTH',
  'SIDEBAR',
  'CUSTOMER_ORDER_MANAGE',
].join(',');

const guide_langIds = [
  'SELECT_FILE',
  'SELECT_LOCAL_FILE',
  'SELECT_DEVICE',
  'NEXT_STEP',
  'SKIP_GUIDE',
  'PREVIOUS_STEP',
  'COMPLETE_GUIDE',
  'OPEN_GUIDE',
  'NOT_OPEN',
  'OPEN',
  'SELECT_MACHINE',
  'SELECT_MACH_ADD',
  'EDIT',
  'CLICK_EDIT_BUTTON',
  'ENTER_PRODUCTION_UNIT_ID',
  'AUXILIARY',
  'SELECT_AUX',
  'SAVE_PRODUCTION_UNIT',
].join(',');

const public_langIds = [
  'IMPORT',
  'ADD',
  'MODIFY',
  'DELETE',
  'OPTION',
  'SELECT_ALL',
  'START_TIME',
  'END_TIME',
  'NUMBER',
  'CANCEL',
  'CONFIRM',
  'PLEASE_ENTER',
  'SAVE',
].join(',');

const device_langIds = [
  'DEVICE_NAME',
  'DEVICE_TYPE',
  'DEVICE_REMARK',
  'IM',
  'PICKER',
  'DRIER',
  'CHILLER',
  'TCU',
].join(',');

const device_manage = [
  'DEVICE_MANAGE',
  'PRODUCTION_INFO',
  'MACH_RESUME',
  'CRAFT_DETAIL',
  'WORK_LINE_OVERVIEW',
  'TEMPERATURE_MONITOR',
  'ENERGY_CONSUMPTION_STATISTICS',
  'REALTIME_INFO',
  'NAME_PARAM',
  'SPECIFICATION',
  'QUALITY_MONITOR',
  'ALARM_RECORD',
  'AUX_INFO',
  'PRODUCTION_LOG',
  'JSON_STRUCTURE',
  'DEVICE_CONFIG_MODIFIED_SUCCESSFULLY',
  'CONFIGURATION_NAME',
  'SELECT_CONFIGURATION',
  'CONFIG_INFO',
  'IMPORT_DEVICE_SUCCEEDED',
  'OTHER_DEVICE',
  'CONFIGURATION_NAME',
  'REPAIR_MAINTENANCE_ADD_SUCCESSFULLY',
  'MODIFIED_DEVICE_NAME_SUCCEED',
  'IMPORT_DEVICE_SUCCESSFULLY',
  'REPAIR',
  'PLANNED_MAINTENANCE',
  'OUT_PLAN_MAINTENANCE',
  'DEVICE_NAME_REQUIRED',
  'DEVICE_CONFIG_MANAGE',
  'IMPORT_OTHER_DEVICE_CONFIG',
  'MANUAL_CONFIG',
  'DEVICE_MAINTENANCE',
  'MODIFY_DEVICE_NAME',
  'DEVICE_NAME',
  'SELECT_DEVICE_FILE',
  'CONFIG_MANAGE',
  'REPAIR_MAINTENANCE',
].join(',');

const module_manage = [
  'NOT_USE',
  'IN_USE',
  'ACTIVE_SERVICE',
  'RETIRE',
  'MODULE_SN',
  'MODULE_NAME',
  'IMAGE',
  'RF_TYPE',
  'MATERIAL',
  'SIZE_SPECIFICATION',
  'MODULE_ASCRIPTION',
  'MODULE_DIAGRAM',
  'LENGTH',
  'WIDTH',
  'HEIGHT',
  'MODULE_WEIGHT',
  'SPRUE_SEAT_RADIAN',
  'STATUS',
  'REMARK',
  'STORAGE_LOCATION',
  'ACTIVE_SERVICE',
  'RETIRE',
  'SCRAP_DATE',
  'SCRAP_DATE',
  'MODIFY_CAVITY',
  'CLAMPLING_FORCE',
  'EJECT_STROKE',
  'CLAMPLING_STROKE',
  'NOZZLE_WEIGHT',
  'EJECTION_WEIGHT',
  'CORE',
  'PICKER',
  'HOT_RUNNER',
  'MOLD_TEMPERATURE_CONTROLLER',
  'CHILLER',
  'LIMIT_SHOT_COUNT',
  'START_USED_TIME',
  'ALERT_SHOT_COUNT',
  'INIT_SHOT_COUNT',
  'SELECT',
  'SELECT_PRODUCT_IMG',
  'DUPLICATE_MODULE_SN',
  'VALUE_NEGATIVE',
  'VALUE_LESS',
  'UPLOAD_PRO_PICTURE_SUCCESSFULLY',
  'MODIFY_MOLD_SUCCESSFULLY',
  'ADD_MOLD_SUCCESSFULLY',
  'MODULE_MANAGE',
  'ADD_MODULE',
  'MODIFY_MODULE',
  'CURRENT_SHOT_COUNT',
  'MODULE_CAVITY',
].join(',');

const customer_manage = [
  'CUSTOMER_NAME',
  'CUSTOMER_SN',
  'ADD_CUSTOMER_SUCCESS',
  'MODIFY_CUSTOMER_SUCCESS',
  'ADD_CUSTOMER',
  'DUPLICATE_CUSTOMER_SN',
  'MODIFY_CUSTOMER',
  'CUSTOMER_MANAGE',
  'LINK',
  'ADDRESS',
  'PHONE',
  'DESCRIPTION',
].join(',');

const product_manage = [
  'PRODUCT_IMAGE',
  'PRODUCT_NAME',
  'PRODUCT_SN',
  'PRODUCT_MANAGE',
  'PRODUCT_WEIGHT',
  'MATERIAL_SN',
  'ADD_PRODUCT_SUCCESS',
  'UPLOAD_PRO_PICTURE_SUCCESSFULLY',
  'MODIFY_PRODUCT_SUCCESS',
  'PRODUCT_WEIGHT_NEED_NUMBER',
  'DUPLICATE_PRODUCT_SN',
  'SELECT_PRODUCT_IMG',
  'UPLOAD_PRO_PICTURE_SUCCESSFULLY',
  'SELECT_PRODUCT_IMG',
  'SELECT',
  'MODIFY_PRODUCT',
  'ADD_PRODUCT',
  'PRODUCT_COLOR',
  'PRODUCT_SPECIFICATION',
].join(',');

const material_manage = [
  'GROUP',
  'MATERIAL_SN',
  'MATERIAL_NAME',
  'NAME_EN',
  'BRAND',
  'STANDARD_ABBREVIATIONS',
  'DESCRIPTION',
  'DENSITY',
  'SHRINKAGE',
  'MIN_MODULE_TEMP',
  'MAX_MODULE_TEMP',
  'MIN_TUBE_TEMP',
  'MAX_TUBE_TEMP',
  'MIN_INJECT_PRESS',
  'MAX_INJECT_PRESS',
  'DRY_TEMP',
  'DRY_TIME',
  'WATER_ABSORPTION',
  'OPTIMUM_LINEAR_SPEED',
  'MAX_LINEAR_SPEED',
  'PLASTICIZING_TEMP',
  'PLASTICIZING_PRESS',
  'INJECT_SPEED',
  'PROPORTION',
  'CHARGE_BACK_PRESS',
  'COMPRESSION_RATIO',
  'LD_RATIO',
  'CREATOR',
  'CREATE_TIME',
  'LAST_UPDATE',
  'LAST_UPDATE_TIME',
  'ADD_MATERIAL_SUCCESS',
  'DUPLICATE_MATERIAL_SN',
  'MODIFY_MATERIAL_SUCCESS',
  'MATERIAL_MANAGE',
  'ADD_MATERIAL',
  'MODIFY_MATERIAL',
].join(',');

const pigment_manage = [
  'PIGMENT_SN',
  'PIGMENT_NAME',
  'PIGMENT_COLOR',
  'DESCRIPTION',
  'ADD_PIGMENT_SUCCESS',
  'MODIFY_PIGMENT_SUCCESS',
  'DUPLICATE_PIGMENT_SN',
  'PIGMENT_MANAGE',
  'ADD_PIGMENT',
  'MODIFY_PIGMENT',
].join(',');

const craft_manage = [
  'PRODUCT_SN',
  'DEVICE_SN',
  'MODULE_SN',
  'CRAFT_SN',
  'CUSTOMER_SN',
  'NOT_AUDITED',
  'STATUS',
  'MANAGEMENT',
  'ARCHIVE',
  'STANDARD_CYCLE',
  'MODULE_CAVITY',
  'PLAN_ORDER',
  'PLAN_OUTPUT',
  'PLAN_START_DATE',
  'PLAN_END_DATE',
  'PARAM_CONFIG',
  'DELETE_CRAFT_SUCCESS',
  'ADD_MANAGE_CRAFT_SUCCESS',
  'DELETE_MANAGE_CRAFT_SUCCESS',
  'MODIFY_MANAGE_CRAFT_SUCCESS',
  'PARAM_CONFIG_STRUCTURE',
  'NON_NEGATIVE',
  'NODULE_CAVITY_INT',
  'STANDARD_CYCLE_GREATER',
  'DUPLICATED',
  'CRAFT_MANAGE',
  'SELECT',
  'MODIFY_CRAFT',
  'ADD_CRAFT',
  'DELETE',
  'WHETHER_DELETE_CRAFT',
].join(',');

const worker_manage = [
  'WORKER_IMG',
  'WORKER_NAME',
  'WORKER_SN',
  'WORKER_ROLE',
  'GUEST',
  'OPERATOR',
  'MECHANIC',
  'ENGINEER',
  'ADMIN',
  'LOGIN_STATUS',
  'PROHIBIT',
  'NORMAL',
  'WORKER_PSW',
  'ADD_WORKER_SUCCESS',
  'MODIFY_WORKER_SUCCESS',
  'WORKER_IMG_UPLOADED_SUCCESS',
  'DELETE_WORKER_SUCCESS',
  'DUPLICATE_WORKER_SN',
  'SELECT_WORKER_IMAGE',
  'WORKER_MANAGE',
].join(',');

const class_manage = [
  'NO',
  'YES',
  'CATEGORY_SN',
  'CATEGORY_NAME',
  'START_DATE',
  'END_DATE',
  'DESCRIPTION',
  'CLASS_SN',
  'CLASS_NAME',
  'START_TIME',
  'END_TIME',
  'INTER_DAY',
  'DELETE_CATEGORY_SUCCESS',
  'ADD_CATEGORY_SUCCESS',
  'MODIFY_CATEGORY_SUCCESS',
  'ADD_CLASS_SUCCESS',
  'MODIFY_CLASS_SUCCESS',
  'DELETE_CLASS_SUCCESS',
  'ADD_CLASS',
  'MODIFY_CLASS_INFO',
  'WHETHER_DELETE_CLASS',
  'ADD_CATEGORY',
  'MODIFY_CATEGORY_INFO',
  'WHETHER_DELETE_CATEGORY',
  'SCHEDULE_MANAGE',
  'DUPLICATE_CATEGORY_SN',
  'PLEASE_ENTER',
].join(',');

const craft_online = [
  'ONLINE_STATE',
  'CRAFT_SN',
  'MACHINE_SN',
  'MACHINE_NAME',
  'MATERIAL',
  'COLOR',
  'MODULE_CAVITY',
  'STATUS',
  'USING',
  'HISTORICAL_USE',
  'CREATE_DATE',
  'CRAFT_JOIN_MANGE_SUCCESS',
  'JOIN_MANAGEMENT',
  'CRAFT_ONLINE',
].join(',');

const system_setting = [
  'SYSTEM_SETTING',
  'PARAMETER',
  'VALUE',
  'DESCRIPTION',
  'OPEN_GUIDE',
  'OPEN_SYSTEM_GUIDE',
  'CLOSE_SYSTEM_GUIDE',
  'GUIDE_SETTING_DESCRIPTION',
].join(',');

const role_manage = [
  'ROLE_NAME',
  'ROLE_DESCRIPTION',
  'ADD_ROLE_SUCCESS',
  'MODIFY_MPS_MENU_SUCCESS',
  'MODIFY_USER_ROLE_SUCCESS',
  'HIDE',
  'SHOW',
  'PLEASE_ENTER',
  'DUPLICATE_ROLE',
  'MPS_MENU_MANAGE',
  'USER_ROLE_MANAGE',
  'ADD_USER_ROLE',
  'MODIFY_USER_ROLE',
  'PRODUCTION_UNIT',
  'GRAPH_OVERVIEW',
  'LIST_OVERVIEW',
  'DETAIL',
  'REALTIME_DETAIL',
  'CRAFT_DETAIL',
  'BASIC_INFO',
  'HISTORY_DETAIL',
  'MAINTENANCE_REPAIR',
  'CRAFT_MANAGE',
  'CRAFT_ONLINE',
  'MANAGE_CRAFT',
  'CRAFT_MONITOR',
  'MODULE_MANAGE',
  'MODULE_INFO',
  'MATERIAL_MANAGE',
  'MATERIAL_INFO',
  'PIGMENT_INFO',
  'PRODUCT_HISTORY',
  'PRODUCT_INFO',
  'DEVICE_MANAGE',
  'MACHINE_OEE_OVERVIEW',
  'MACHINE_OEE_QUERY',
  'MACHINE_OEE_COMPARE',
  'SYSTEM_MANAGE',
  'SYSTEM_SETTING',
].join(',');

const workline = [
  'PLEASE_SELECT',
  'MODIFY_PRODUCTION_UNIT_SUCCESS',
  'ADD_PRODUCTION_UNIT_SUCCESS',
  'MODIFY_PRODUCTION_UNIT',
  'ADD_PRODUCTION_UNIT',
  'PRODUCTION_UNIT_ID_NOT_NULL',
  'DUPLICATE_PRODUCTION_UNIT_ID',
  'MACHINE_SN',
  'MACHINE_NAME',
  'MACH_MODEL',
  'VERSION',
  'NOT_ADD',
  'PRODUCTION_UNIT_ID',
  'WORKLINE_MANAGE',
  'IM',
  'PICKER',
  'CHILLER',
  'DRIER',
  'TCU',
].join(',');

const craft_param = [
  'CRAFT_NAME',
  'CRAFT_SN',
  'ADD_CRAFT_SUCCESS',
  'MODIFY_CRAFT_SUCCESS',
  'CRAFT_PARAM_STRUCTURE',
  'DUPLICATE_CRAFT_SN',
  'PARAMETER',
  'CRAFT_PARAM_MANAGE',
  'ADD_CRAFT',
  'MODIFY_CRAFT',
].join(',');

const customer_order = [
  'CUSTOMER_ORDER_MANAGE',
  'ORDER_SN',
  'ORDER_SN',
  'CUSTOMER_SN',
  'CUSTOMER_NAME',
  'PRODUCT_SN',
  'PRODUCT_NAME',
  'PRODUCT_IMAGE',
  'ORDER_COUNT',
  'DELIVERY',
  'DESCRIPTION',
  'CUSTOMER_ORDER_DELETED_SUCCESS',
  'ADD_CUSTOMER_ORDER_SUCCESS',
  'CUSTOMER_ORDER_MODIFIED_SUCCESS',
  'SELECT',
  'ADD_CUSTOMER_ORDER',
  'MODIFY_CUSTOMER_ORDER',
  'WHETHER_DELETE_CUSTOMER_ORDER',
  'ORDER_COUNT_CAN_NOT_BE_AN_INTEGER_LESS_THAN_1',
].join(',');

const additive_manage = [
  'DESCRIPTION',
  'ADDITIVE_SN',
  'ADDITIVE_NAME',
  'ADDITIVE_MANAGE',
  'DUPLICATE_ADDITIVE_SN',
  'MODIFY_ADDITIVE_SUCCESS',
  'ADD_ADDITIVE_SUCCESS',
  'ADD_ADDITIVE',
  'MODIFY_ADDITIVE',
].join(',');

export {
  side_langIds,
  guide_langIds,
  public_langIds,
  device_langIds,
  device_manage,
  module_manage,
  customer_manage,
  product_manage,
  material_manage,
  pigment_manage,
  craft_manage,
  worker_manage,
  class_manage,
  craft_online,
  system_setting,
  role_manage,
  workline,
  craft_param,
  customer_order,
  additive_manage,
};
