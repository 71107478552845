import { useEffect } from 'react';
import useRequest from '@/websdk/utils/request';

export default (function (id) {
  const { exec, isRequestSuccessed, rst } = useRequest({
    url: '',
    name: 'DELETE_WORKER',
    method: 'DELETE',
  });

  useEffect(() => {
    if (id) exec(`/api/workers/${id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return [rst, isRequestSuccessed];
});
