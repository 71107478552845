import React from 'react';
import sharedState from '../../utils/custom';

const initState = {
  list: [],
  devsParams: {},
};

const actions = {
  setDevList: function (store, list) {
    store.setState({ list });
  },
  setDevsParams: function (store, devsParams) {
    store.setState({ devsParams });
  },
};

const useStore = sharedState(React, initState, actions);

export default useStore;
