import React, { useState } from 'react';
import {
  EuiButtonEmpty,
  EuiContextMenuItem,
  EuiContextMenuPanel,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPagination,
  EuiPopover,
} from '@elastic/eui';
import { useLanguageList } from '@/server';

export default (function (props) {
  const languages = useLanguageList({
    ids: 'ROWS_PER_PAGE,ROWS',
    lang_codes: localStorage.lang,
  });
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [activePage, setActivePage] = useState(props.pagination.pageIndex);
  const [rowSize, setRowSize] = useState(props.pagination.pageSize);

  const PAGE_COUNT = props.pagination.totalItemCount
    ? Math.ceil(props.pagination.totalItemCount / rowSize)
    : 0;
  const onButtonClick = () => {
    setIsPopoverOpen(isPopoverOpen => !isPopoverOpen);
  };

  const closePopover = () => {
    setIsPopoverOpen(false);
  };

  const goToPage = pageNumber => {
    setActivePage(pageNumber);
    props.pageChange({ pageIndex: pageNumber, pageSize: rowSize });
  };

  const getIconType = size => {
    return size === rowSize ? 'check' : 'empty';
  };

  const button = (
    <EuiButtonEmpty
      size="s"
      color="text"
      iconType="arrowDown"
      iconSide="right"
      onClick={onButtonClick}
    >
      {languages.ROWS_PER_PAGE}: {rowSize}
    </EuiButtonEmpty>
  );

  const renderItems = () => {
    let items_ = [];
    props.pagination.pageSizeOptions.forEach(option => {
      items_.push(
        <EuiContextMenuItem
          key={option}
          icon={getIconType(option)}
          onClick={() => {
            closePopover();
            setRowSize(option);
            props.pageChange({ pageIndex: 0, pageSize: option });
          }}
        >
          {option} {languages.ROWS}
        </EuiContextMenuItem>
      );
    });
    return items_;
  };

  const items = renderItems();

  return (
    <EuiFlexGroup justifyContent="spaceBetween" alignItems="center">
      <EuiFlexItem grow={false}>
        <EuiPopover
          button={button}
          isOpen={isPopoverOpen}
          closePopover={closePopover}
          panelPaddingSize="none"
        >
          <EuiContextMenuPanel items={items} />
        </EuiPopover>
      </EuiFlexItem>

      <EuiFlexItem grow={false}>
        <EuiPagination
          aria-label="pagination"
          pageCount={PAGE_COUNT}
          activePage={activePage}
          onPageClick={goToPage}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
});
