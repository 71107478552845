import useRequest from '@/websdk/utils/request';

export default (function (manual) {
  const { data, isRequestSuccessed } = useRequest({
    name: 'GET_USER_CONFIG_LIST',
    url: '/api/user/0/config',
    manual,
  });

  return [data, isRequestSuccessed];
});
