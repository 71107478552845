import { useEffect } from 'react';
import apis from './apis';
import useRequest from '../../utils/request';
import useStore from './store';

export default (function () {
  const [, dispatch] = useStore();

  const { data } = useRequest(apis.getLoginUser);

  useEffect(() => {
    if (data !== null) dispatch.setLoginInfo(data);
  }, [data, dispatch]);

  return data || {};
});
