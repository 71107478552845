import en_US from './en_US';
import zh_CN from './zh_CN';
import zh_TW from './zh_TW';

let locale = {};
locale['en-US'] = en_US;
locale['zh-CN'] = zh_CN;
locale['zh-TW'] = zh_TW;

export default locale;
