import useRequest from '@/websdk/utils/request';

export default (function () {
  const { exec, isRequestSuccessed } = useRequest({
    url: '/api/products',
    name: 'POST_PRODUCT',
    method: 'POST',
  });

  return [exec, isRequestSuccessed];
});
