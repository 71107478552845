import { useEffect, useState } from 'react';
import useRequest from '@/websdk/utils/request';

export default (function (param) {
  const [language, setLanguage] = useState({});
  let url = ``;
  if (param && JSON.stringify(param) !== '{}') {
    url = `/api/language/strings`;
    Object.keys(param).forEach((key, index) => {
      if (index === 0) url += '?';
      else url += '&';
      url += `${key}=${param[key]}`;
    });
  }

  const { data } = useRequest({
    url: url,
    name: 'GET_LANGUAGES',
    manual: false,
  });

  let newLanguage = {};

  if (param && param.ids) {
    param.ids.split(',').forEach(id => {
      newLanguage[id] = id;
    });
  }

  useEffect(() => {
    if (data && JSON.stringify(data) !== '{}') {
      Object.keys(data).forEach(key => {
        newLanguage[key] = data[key].value[param.lang_codes ? param.lang_codes : 'en-US'];
      });
      setLanguage(newLanguage);
    } else {
      setLanguage(newLanguage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return JSON.stringify(language) === '{}' ? newLanguage : language;
});
