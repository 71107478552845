import React from 'react';
import { EuiHeader, EuiIcon, EuiPage, EuiHeaderLinks, EuiSuperSelect } from '@elastic/eui';
import '@elastic/eui/dist/eui_theme_light.css';
import './layout.css';
import CollapsibleNav from './sidebar.js';
import { useLogout, useLoginRoles, useLoginInfo, useRefreshToken } from '@/websdk/data';
import Toast from '@/websdk/components/toast';
import logo from '@/assets/images/logo.png';
import { setCookie } from '@/websdk/utils/common';
import { useLanguageList } from '@/server';
import lang from '@/constant/lang';

export default (function (props) {
  localStorage.lang = lang;
  const languages = useLanguageList({
    ids: 'BACKGROUND_MANAGE_SYSTEM,TMPLASCLOUD,ADMIN,INDEX,LOGOUT',
    lang_codes: localStorage.lang,
  });
  const logout = useLogout('/login.html');
  const info = useLoginInfo();
  const roles = useLoginRoles(info.id);
  useRefreshToken();

  return (
    <div>
      <EuiHeader
        position="fixed"
        className="iiws__header"
        sections={[
          {
            items: [
              <CollapsibleNav />,
              <div>
                <img style={{ height: '48px' }} src={logo} alt="logo" />
              </div>,
              <div style={{ marginLeft: 8, marginRight: 16 }}>
                <div style={{ fontSize: 24, color: 'white' }}>{languages.TMPLASCLOUD}</div>
              </div>,
              <div>{languages.BACKGROUND_MANAGE_SYSTEM}</div>,
            ],
            borders: 'none',
          },
          {
            items: [
              <EuiHeaderLinks gutterSize="xs" popoverBreakpoints={['xs', 's', 'm']}>
                <button
                  onClick={() => {
                    window.location = 'home.html';
                  }}
                >
                  {languages.INDEX}
                </button>
                <div className="iiws__headerDivide"></div>
                <div className="iiws_langSelect">
                  <EuiSuperSelect
                    className="iiws__headerSelect"
                    options={[
                      {
                        value: 'zh-CN',
                        inputDisplay: '中文简体',
                      },
                      {
                        value: 'zh-TW',
                        inputDisplay: '中文繁体',
                      },
                      {
                        value: 'en-US',
                        inputDisplay: 'English',
                      },
                    ]}
                    valueOfSelected={lang}
                    onChange={lang => {
                      localStorage.lang = lang;
                      setCookie('lang', lang, '/');
                      window.location.reload();
                    }}
                  />
                </div>
                <div>
                  <EuiIcon id="iiws__userLogo" type="user" size="l" style={{ marginRight: 4 }} />
                  {info.login}
                  {roles.includes('UserRoleManager') ? `(${languages.ADMIN})` : ''}
                </div>
                <div className="iiws__headerDivide"></div>
                <div style={{ marginRight: 16 }}>
                  <button onClick={logout}>{languages.LOGOUT}</button>
                </div>
              </EuiHeaderLinks>,
            ],
            borders: 'none',
          },
        ]}
      />
      <EuiPage style={{ paddingTop: 49 }}>
        <div style={{ marginTop: 16, width: '100%', height: '100%' }}>{props.content}</div>
        <Toast />
      </EuiPage>
    </div>
  );
});
