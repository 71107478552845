import React from 'react';
import { useState, useEffect } from 'react';
import apis from './apis';
import useRequest from '../../utils/request';
import { getCookie, setCookie } from '../../utils/common';
import sharedState from '../../utils/custom';

const initState = {
  refreshTime: getCookie('refresh_time'),
};

const actions = {
  setRefreshTime: function (store, refreshTime) {
    store.setState({ refreshTime });
  },
};

const useTokenTime = sharedState(React, initState, actions);

export { useTokenTime };

export default (function (manual) {
  const [refreshTime, dispatch] = useTokenTime();
  const { rst, exec: refreshToken } = useRequest({ ...apis.getRefreshToken, manual: true });
  const [refreshTokenTimeoutId, setRefreshTokenTimeoutId] = useState(null);
  const [refreshTokenIntervalId, setRefreshTokenIntervalId] = useState(null);

  useEffect(() => {
    if (!manual) {
      const regularRefreshToken = () => {
        refreshToken();
        //每一个小时检查一次，距离上次刷新token时间是否超过半个小时
        const id = setInterval(function () {
          let time = parseInt(getCookie('refresh_time'));
          let now = new Date().valueOf();
          if (now - time >= 1800 * 1000) refreshToken();
        }, 3600 * 1000);
        setRefreshTokenIntervalId(id);
      };

      const id = setTimeout(function () {
        let time = parseInt(getCookie('refresh_time'));
        let now = new Date().valueOf();
        //当当前时间据上次刷新时间超过半个小时时或者上次刷新时间未设置时立即刷新token
        if (isNaN(time) || now - time >= 1800 * 1000) {
          regularRefreshToken();
        }
        //当当前时间据上次刷新token时间未超过半个小时时，则等到距离上次刷新时间半个小时时触发第二个定时器并刷新token
        else {
          const id = setTimeout(function () {
            regularRefreshToken();
          }, time + 1800 * 1000 - now);
          setRefreshTokenTimeoutId(id);
        }
      }, 10 * 1000);
      setRefreshTokenTimeoutId(id);
    }
  }, [refreshToken, manual]);

  useEffect(() => {
    if (rst === 200) {
      const time = new Date().valueOf();
      dispatch.setRefreshTime(time);
      setCookie('refresh_time', time, '/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rst]);

  useEffect(() => {
    return () => {
      if (refreshTokenIntervalId !== null) clearInterval(refreshTokenIntervalId);
    };
  }, [refreshTokenIntervalId]);

  useEffect(() => {
    return () => {
      if (refreshTokenTimeoutId !== null) clearTimeout(refreshTokenTimeoutId);
    };
  }, [refreshTokenTimeoutId]);

  if (manual) return refreshToken;
  else return refreshTime;
})
