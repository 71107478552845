import useRequest from '@/websdk/utils/request';

export default (function () {
  const { data, exec, isRequestSuccessed } = useRequest({
    url: '/api/v1/file/upload',
    name: 'POST_FILE',
    method: 'POST',
  });

  return [data, exec, isRequestSuccessed];
});
