import useRequest from '@/websdk/utils/request';

export default (function () {
  const { exec, isRequestSuccessed } = useRequest({
    url: '/api/user-roles',
    name: 'POST_USER_ROLE',
    method: 'POST',
  });

  return [exec, isRequestSuccessed];
});
