import React from 'react';
import sharedState from '../../utils/custom';

const initState = {
  roles: [],
  info: null,
  loginUrl: '/login.html',
};

const actions = {
  setLoginRoles: function (store, roles) {
    store.setState({ roles });
  },
  setLoginInfo: function (store, info) {
    store.setState({ info });
  },
  setLoginUrl: function (store, loginUrl) {
    store.setState({ loginUrl });
  },
};

const useStore = sharedState(React, initState, actions);

export default useStore;
