import React, { useState, useEffect } from 'react';

import { EuiGlobalToastList } from '@elastic/eui';

export default (function (props) {
  const [toasts, setToasts] = useState([]);

  const addToastHandler = toast => {
    setToasts(toasts.concat(toast));
  };

  const removeToast = removedToast => {
    setToasts(toasts.filter(toast => toast.id !== removedToast.id));
  };

  useEffect(() => {
    if (props.msg) {
      addToastHandler(props.msg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.msg]);

  return (
    <div style={{ maxWidth: 320 }}>
      <EuiGlobalToastList toasts={toasts} dismissToast={removeToast} toastLifeTimeMs={6000} />
    </div>
  );
});
