// import { useEffect } from 'react';
import useRequest from '@/websdk/utils/request';

export default (function () {
  const { exec, isRequestSuccessed } = useRequest({
    url: '/api/produce-classes',
    name: 'PUT_PRODUCE_CLASSES',
    method: 'PUT',
  });

  return [exec, isRequestSuccessed];
});
