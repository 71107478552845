import useRequest from '@/websdk/utils/request';
export default (function () {
  const { data, isRequestSuccessed } = useRequest({
    url: '/api/online-crafts',
    name: 'GET_ONLINE_CRAFT_LIST',
    manual: false,
  });

  return [data || [], isRequestSuccessed];
});
