import useRequest from '@/websdk/utils/request';

export default (function (manual) {
  const url = '/api/crafts';

  const { data, exec } = useRequest({
    name: 'GET_CRAFT_LIST',
    url,
    manual,
  });

  return [data || [], exec];
});
