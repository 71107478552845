import useRequest from '@/websdk/utils/request';

export default (function (path) {
  const url = `/api/access-control?access_path=${path}&access_method=PUT`;

  const { data } = useRequest({
    name: 'GET_ACCESS',
    url,
  });

  return data;
});
