import { useState, useEffect } from 'react';
import useAxios from 'axios-hooks';
import useHttpMsg from './http_msg.js';
import useStore from '../data/login/store';

//manual为true时，可以使用返回的exec函数手动执行
//GET请求由用户设置manual且无需传method参数
function useRequest({ url, method, data, name, manual }) {
  const [isRequestSuccessed, setIsRequestSuccessed] = useState(false);
  const [{ loginUrl }] = useStore();
  let option = { url };
  //处理非GET请求
  if (method && method !== 'GET') {
    option = { ...option, method };
    manual = true;
  }
  if (data) option = { ...option, data };
  //console.log(option);

  const [, handleHttpMsg] = useHttpMsg();
  const [{ response, loading: isLoading, error }, exec] = useAxios(option, { manual });
  const [res, setRes] = useState({});

  useEffect(() => {
    if (error && error.response) {
      console.log(error);
      setRes({ ...res, rst: error.response.status, msg: '' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (response && response.data) setRes(response.data);
  }, [response]);

  useEffect(() => {
    if (res && res.rst) {
      if (res.rst === 401) window.location = loginUrl;
      else {
        if (!isLoading && res.rst === 200) {
          setIsRequestSuccessed(true);
        }
        handleHttpMsg.change(res.rst, name, res.msg);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [res]);

  useEffect(() => {
    if (isLoading) {
      setIsRequestSuccessed(false);
    }
  }, [isLoading]);

  return { exec, isLoading, ...res, isRequestSuccessed };
}

export default useRequest;
