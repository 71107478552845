import React, { useState, useEffect } from 'react';
import Layout from '@/layout';
import '@elastic/eui/dist/eui_theme_light.css';
import {
  EuiButtonEmpty,
  EuiSelect,
  EuiTextArea,
  EuiButton,
  EuiOverlayMask,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiForm,
  EuiFormRow,
  EuiDatePicker,
  EuiFieldText,
  EuiTabs,
  EuiTab,
  EuiCheckboxGroup,
  EuiCheckbox,
  EuiFilePicker,
  EuiComboBox,
} from '@elastic/eui';
import render from '@/websdk/utils/render.js';
import {
  useDevList,
  useGetDevConfig,
  usePutDevConfig,
  usePutMaintain,
  useImportConfig,
  usePutDevice,
  useGetUserConfig,
  usePutUserConfig,
  useImportDevices,
  useAccessControl,
  useLanguageList,
} from '@/server';
import Table from '@/classes/table';
import moment from 'moment';
import Toast from '@/classes/toast';
import uuid from 'react-uuid';
import Driver from 'driver.js';
import 'driver.js/dist/driver.min.css';
import { guide_langIds, public_langIds, device_langIds, device_manage } from '@/constant/lang_ids';

function Home() {
  const languages = useLanguageList({
    ids: [guide_langIds, public_langIds, device_langIds, device_manage].join(','),
    lang_codes: localStorage.lang,
  });
  const guide = [
    {
      element: '#select_devs_file',
      popover: {
        title: languages.SELECT_FILE,
        description: languages.SELECT_LOCAL_FILE,
        position: 'bottom',
      },
    },
    {
      element: '#device_import',
      popover: {
        title: languages.IMPORT,
        description: languages.SELECT_DEVICE,
        position: 'left',
        doneBtnText: languages.NEXT_STEP,
      },
    },
    {
      //防止点击第二步的下一步后进入onReset
      element: '#device_import',
    },
  ];
  const columns = [
    {
      field: 'name',
      name: languages.DEVICE_NAME,
    },
    {
      field: 'type_title',
      name: languages.DEVICE_TYPE,
      sortable: true,
    },
    {
      field: 'remark',
      name: languages.DEVICE_REMARK,
    },
  ];
  const options = [
    {
      value: 'adaptor-productions-current',
      text: languages.PRODUCTION_INFO,
    },
    {
      value: 'base-resume',
      text: languages.MACH_RESUME,
    },
    {
      value: 'craft',
      text: languages.CRAFT_DETAIL,
    },
    {
      value: 'group-preview',
      text: languages.WORK_LINE_OVERVIEW,
    },
    {
      value: 'history-temperature-monitor',
      text: languages.TEMPERATURE_MONITOR,
    },
    {
      value: 'monitor-energy-consumption',
      text: languages.ENERGY_CONSUMPTION_STATISTICS,
    },
    {
      value: 'monitor-real-time-info',
      text: languages.REALTIME_INFO,
    },
    {
      value: 'name-param',
      text: languages.NAME_PARAM,
    },
    {
      value: 'specification-param',
      text: languages.SPECIFICATION,
    },
    {
      value: 'history-quality-monitor',
      text: languages.QUALITY_MONITOR,
    },
    {
      value: 'alarm-config',
      text: languages.ALARM_RECORD,
    },
    {
      value: 'monitor-auxiliary',
      text: languages.AUX_INFO,
    },
    {
      value: 'produce-log-conf',
      text: languages.PRODUCTION_LOG,
    },
  ];

  const accessControl = useAccessControl('/api/v1/devices/*/configs/*');
  const [authority, setAuthority] = useState(true);
  const [userConfig, isGetSuccessed] = useGetUserConfig();
  const putUserConfig = usePutUserConfig()[0];
  const [devId, setDevId] = useState(null);
  const [configKey, setConfigKey] = useState(null);
  const [config, setConfig] = useState(null);
  const [param, setParam] = useState(null);
  const putDevConfig = usePutDevConfig(devId, configKey, config);
  const putDeviceName = usePutDevice(param);
  const [option, setOption] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isShowModalVisible, setIsShowModalVisible] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [importDevices, isImportDevicesSuccessed] = useImportDevices();
  const closeModal = () => {
    setIsModalVisible(false);
    setId(null);
    setKey(null);
  };

  const driver = new Driver({
    allowClose: false,
    closeBtnText: languages.SKIP_GUIDE,
    prevBtnText: languages.PREVIOUS_STEP,
    nextBtnText: languages.NEXT_STEP,
    doneBtnText: languages.COMPLETE_GUIDE,
    onNext: ele => {
      if (ele.getNode().getAttribute('id') === 'device_import')
        window.open('/worklines.html', '_self');
    },
    onReset: () => {
      putUserConfig({ data: { showGuide: false } });
    },
  });

  const closeShowModal = () => {
    setIsShowModalVisible(false);
    putUserConfig({ data: { showGuide: false } });
  };

  const [selectValue, setSelectValue] = useState('');
  const [value, setValue] = useState('');
  const [isInvalid, setIsInvalid] = useState(true);
  const [errors, setErrors] = useState([]);
  const [machName, setMachName] = useState('');

  const onChange = e => {
    setSelectValue(e.target.value);
    setKey(e.target.value);
    setValue('');
  };

  const onTextChange = e => {
    if (typeof e.target.value === 'object') {
      setValue(JSON.stringify(e.target.value));
      setIsInvalid(false);
    } else {
      setValue(e.target.value);
      const errStr = languages.JSON_STRUCTURE;
      try {
        const value_ = JSON.parse(e.target.value);
        if (typeof value_ !== 'object') {
          setErrors([errStr]);
          setIsInvalid(true);
        } else {
          setErrors([]);
          setIsInvalid(false);
        }
      } catch (error) {
        setErrors([errStr]);
        setIsInvalid(true);
      }
    }
  };

  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [key, setKey] = useState('');
  const [maintain, setMaintain] = useState({});
  const devConfig = useGetDevConfig(id, key);

  useEffect(() => {
    if (devConfig) {
      setValue(JSON.stringify(devConfig, null, '\t'));
    } else {
      setValue('');
    }
  }, [devConfig]);

  useEffect(() => {
    setAuthority(accessControl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessControl]);

  useEffect(() => {
    if (isGetSuccessed && (!userConfig || userConfig.showGuide)) {
      setIsShowModalVisible(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userConfig, isGetSuccessed]);

  useEffect(() => {
    if (putDevConfig.isRequestSuccessed) {
      setToastMsg({
        id: uuid(),
        title: languages.DEVICE_CONFIG_MODIFIED_SUCCESSFULLY,
        color: 'success',
      });
      setIsModalVisible(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [putDevConfig.isRequestSuccessed]);

  const [devList, updateDevList] = useDevList();
  const [devs, setDevs] = useState([]);
  useEffect(() => {
    if (devList.length !== 0) {
      let devs_ = [];
      devList.forEach(dev => {
        if (languages[dev.type]) {
          devs_.push({ ...dev, type_title: languages[dev.type] });
        } else {
          devs_.push({ ...dev });
        }
      });
      console.info(languages);
      setDevs(devs_);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devList, languages]);
  let modal = null;

  const setConfigForm = (
    <EuiForm>
      <EuiFormRow label={languages.CONFIGURATION_NAME}>
        <EuiSelect
          id="machSelect"
          placeholder={languages.SELECT_CONFIGURATION}
          options={options}
          value={selectValue}
          onChange={onChange}
        />
      </EuiFormRow>
      <EuiFormRow isInvalid={isInvalid} error={errors} label={languages.CONFIG_INFO}>
        <EuiTextArea
          id="machInfo"
          placeholder=""
          value={value}
          onChange={onTextChange}
          isInvalid={isInvalid}
        />
      </EuiFormRow>
    </EuiForm>
  );

  const otherDevs = devList
    .map(dev => {
      return { value: dev.id, text: dev.name, label: `${dev.sn}(${dev.name})` };
    })
    .filter(option => {
      return option.value !== id;
    });

  const [otherDevId, setOtherDevId] = useState('');
  const [selected, setSelected] = useState([]);
  const [checkboxIdToSelectedMap, setCheckboxIdToSelectedMap] = useState({});
  const [allChecked, setAllChecked] = useState(true);
  const allCheckedOnChange = checked => {
    let checkboxIdToSelectedMap = {};
    options.forEach(option => {
      checkboxIdToSelectedMap[option.value] = checked;
    });
    setCheckboxIdToSelectedMap(checkboxIdToSelectedMap);
    setAllChecked(checked);
  };
  const [importConfig, isImportConfigSuccess] = useImportConfig();
  useEffect(() => {
    if (isImportConfigSuccess) {
      setToastMsg({
        id: uuid(),
        title: languages.IMPORT_DEVICE_SUCCEEDED,
        color: 'success',
      });
      setIsModalVisible(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isImportConfigSuccess]);

  const importConfigForm = (
    <EuiForm>
      <EuiFormRow label={languages.OTHER_DEVICE}>
        <EuiComboBox
          id="selectDocExample"
          options={otherDevs}
          singleSelection={true}
          onChange={e => {
            setOtherDevId(e.length !== 0 ? e[0].value : '');
            setSelected(e);
          }}
          selectedOptions={selected}
        />
      </EuiFormRow>
      <EuiFormRow label={languages.CONFIGURATION_NAME}>
        <>
          <EuiCheckbox
            id="all"
            label={languages.SELECT_ALL}
            checked={allChecked}
            onChange={e => allCheckedOnChange(e.target.checked)}
          />
          <EuiCheckboxGroup
            options={options.map(option => {
              return { id: option.value, label: option.text };
            })}
            idToSelectedMap={checkboxIdToSelectedMap}
            onChange={optionId => {
              const newCheckboxIdToSelectedMap = {
                ...checkboxIdToSelectedMap,
                ...{
                  [optionId]: !checkboxIdToSelectedMap[optionId],
                },
              };
              let allChecked = true;
              for (let key in newCheckboxIdToSelectedMap) {
                if (!newCheckboxIdToSelectedMap[key]) {
                  allChecked = false;
                  break;
                }
              }
              setAllChecked(allChecked);
              setCheckboxIdToSelectedMap(newCheckboxIdToSelectedMap);
            }}
          />
        </>
      </EuiFormRow>
    </EuiForm>
  );

  const [putMaintainData, setPutMaintainData] = useState(null);
  const isPutMaintainRequestSuccessed = usePutMaintain(putMaintainData);
  const [toastMsg, setToastMsg] = useState(null);
  const [nameErrors, setNameErrors] = useState([]);
  useEffect(() => {
    if (isPutMaintainRequestSuccessed) {
      setToastMsg({
        id: uuid(),
        title: languages.REPAIR_MAINTENANCE_ADD_SUCCESSFULLY,
        color: 'success',
      });
      setIsModalVisible(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPutMaintainRequestSuccessed]);

  useEffect(() => {
    if (putDeviceName.isRequestSuccessed) {
      setToastMsg({
        id: uuid(),
        title: languages.MODIFIED_DEVICE_NAME_SUCCEED,
        color: 'success',
      });
      setIsModalVisible(false);
      updateDevList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [putDeviceName.isRequestSuccessed]);

  useEffect(() => {
    if (isImportDevicesSuccessed) {
      setToastMsg({
        id: uuid(),
        title: languages.IMPORT_DEVICE_SUCCESSFULLY,
        color: 'success',
      });
      setIsModalVisible(false);
      updateDevList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isImportDevicesSuccessed]);

  const maintainForm = (
    <EuiForm>
      <EuiFormRow key="id" label={languages.OPTION}>
        <EuiSelect
          options={[
            { value: 33025, text: languages.REPAIR },
            { value: 33026, text: languages.PLANNED_MAINTENANCE },
            { value: 33027, text: languages.OUT_PLAN_MAINTENANCE },
          ]}
          value={maintain.id}
          onChange={e => {
            let maintain_ = JSON.parse(JSON.stringify(maintain));
            maintain_.id = parseInt(e.target.value);
            maintain_.start = moment(maintain_.start);
            maintain_.end = moment(maintain_.end);
            setMaintain(maintain_);
          }}
        />
      </EuiFormRow>
      <EuiFormRow key="start" label={languages.START_TIME}>
        <EuiDatePicker
          showTimeSelect
          selected={maintain.start}
          onChange={date => {
            let maintain_ = JSON.parse(JSON.stringify(maintain));
            maintain_.start = date;
            maintain_.end = moment(maintain_.end);
            setMaintain(maintain_);
          }}
        />
      </EuiFormRow>
      <EuiFormRow key="end" label={languages.END_TIME}>
        <EuiDatePicker
          showTimeSelect
          selected={maintain.end}
          onChange={date => {
            let maintain_ = JSON.parse(JSON.stringify(maintain));
            maintain_.start = moment(maintain_.start);
            maintain_.end = date;
            setMaintain(maintain_);
          }}
        />
      </EuiFormRow>
      <EuiFormRow key="sn" label={languages.NUMBER}>
        <EuiFieldText
          value={maintain.sn}
          onChange={e => {
            let maintain_ = JSON.parse(JSON.stringify(maintain));
            maintain_.sn = e.target.value;
            maintain_.start = moment(maintain_.start);
            maintain_.end = moment(maintain_.end);
            setMaintain(maintain_);
          }}
        />
      </EuiFormRow>
    </EuiForm>
  );

  const inputOnChange = e => {
    setMachName(e.target.value);
    if (!e.target.value) {
      setNameErrors([languages.DEVICE_NAME_REQUIRED]);
    } else {
      setNameErrors([]);
    }
  };

  const [tabSelected, setTabSelected] = useState('import_config');
  if (isModalVisible) {
    if (option === 'config') {
      modal = (
        <div>
          <EuiOverlayMask>
            <EuiModal onClose={closeModal} initialFocus="[id=machSelect]">
              <EuiModalHeader>
                <EuiModalHeaderTitle>
                  {name}
                  {languages.DEVICE_CONFIG_MANAGE}
                </EuiModalHeaderTitle>
              </EuiModalHeader>

              <EuiModalBody>
                <EuiTabs style={{ marginBottom: 16 }}>
                  <EuiTab
                    onClick={() => {
                      setTabSelected('import_config');
                    }}
                    isSelected={tabSelected === 'import_config'}
                    key="import_config"
                  >
                    {languages.IMPORT_OTHER_DEVICE_CONFIG}
                  </EuiTab>
                  <EuiTab
                    onClick={() => {
                      setTabSelected('set_config');
                    }}
                    isSelected={tabSelected === 'set_config'}
                    key="set_config"
                  >
                    {languages.MANUAL_CONFIG}
                  </EuiTab>
                </EuiTabs>
                {tabSelected === 'import_config' ? importConfigForm : setConfigForm}
              </EuiModalBody>

              <EuiModalFooter>
                {tabSelected === 'import_config' ? (
                  <EuiButton
                    onClick={() => {
                      let keys = [];
                      for (let key in checkboxIdToSelectedMap) {
                        if (checkboxIdToSelectedMap[key]) keys.push(key);
                      }
                      importConfig({
                        data: { target_id: id, source_id: otherDevId, config_keys: keys },
                      });
                    }}
                    fill
                  >
                    {languages.IMPORT}
                  </EuiButton>
                ) : (
                  <EuiButton
                    onClick={() => {
                      if (!isInvalid) {
                        setDevId(id);
                        setConfigKey(key);
                        setConfig(JSON.parse(value));
                      }
                    }}
                    fill
                  >
                    {languages.MODIFY}
                  </EuiButton>
                )}
              </EuiModalFooter>
            </EuiModal>
          </EuiOverlayMask>
        </div>
      );
    } else if (option === 'maintain') {
      modal = (
        <div>
          <EuiOverlayMask>
            <EuiModal onClose={closeModal}>
              <EuiModalHeader>
                <EuiModalHeaderTitle>
                  {name}
                  {languages.DEVICE_MAINTENANCE}
                </EuiModalHeaderTitle>
              </EuiModalHeader>

              <EuiModalBody>{maintainForm}</EuiModalBody>

              <EuiModalFooter>
                <EuiButton
                  onClick={() => {
                    setPutMaintainData({
                      devId: id,
                      data: [
                        {
                          id: maintain.id,
                          start: parseInt(maintain.start.valueOf() / 1000),
                          end: parseInt(maintain.start.valueOf() / 1000),
                          sn: maintain.sn,
                        },
                      ],
                    });
                  }}
                  fill
                >
                  {languages.ADD}
                </EuiButton>
              </EuiModalFooter>
            </EuiModal>
          </EuiOverlayMask>
        </div>
      );
    } else if (option === 'editDeviceName') {
      modal = (
        <div>
          <EuiOverlayMask>
            <EuiModal onClose={closeModal}>
              <EuiModalHeader>
                <EuiModalHeaderTitle>{languages.MODIFY_DEVICE_NAME}</EuiModalHeaderTitle>
              </EuiModalHeader>
              <EuiModalBody>
                <EuiForm>
                  <EuiFormRow
                    isInvalid={nameErrors.length !== 0}
                    error={nameErrors}
                    label={languages.DEVICE_NAME}
                  >
                    <EuiFieldText
                      name="name"
                      value={machName}
                      onChange={inputOnChange}
                      isInvalid={nameErrors.length !== 0}
                    />
                  </EuiFormRow>
                </EuiForm>
              </EuiModalBody>
              <EuiModalFooter>
                <EuiButtonEmpty onClick={closeModal}>{languages.CANCEL}</EuiButtonEmpty>
                <EuiButtonEmpty
                  onClick={() => {
                    if (nameErrors.length === 0) {
                      setParam({ id: devId, name: machName });
                    }
                  }}
                  isDisabled={nameErrors.length !== 0}
                >
                  {languages.CONFIRM}
                </EuiButtonEmpty>
              </EuiModalFooter>
            </EuiModal>
          </EuiOverlayMask>
        </div>
      );
    }
  }

  let isShowGuide;
  if (isShowModalVisible) {
    isShowGuide = (
      <div>
        <EuiOverlayMask>
          <EuiModal onClose={closeShowModal}>
            <EuiModalHeader>
              <EuiModalHeaderTitle>{`${languages.OPEN_GUIDE}?`}</EuiModalHeaderTitle>
            </EuiModalHeader>
            <EuiModalFooter>
              <EuiButton onClick={closeShowModal}>{languages.NOT_OPEN}</EuiButton>
              <EuiButton
                onClick={() => {
                  // putUserConfig({ data: { showGuide: true } });
                  setIsShowModalVisible(false);
                  driver.defineSteps(guide);
                  driver.start();
                  const activeElement = driver.getHighlightedElement();
                  const stageNode = document.getElementById('driver-highlighted-element-stage');
                  stageNode.style.setProperty(
                    'left',
                    `${activeElement.getCalculatedPosition().left - 24}px`
                  );
                }}
              >
                {languages.OPEN}
              </EuiButton>
            </EuiModalFooter>
          </EuiModal>
        </EuiOverlayMask>
      </div>
    );
  }

  return (
    <div>
      <Layout
        content={
          <div>
            <EuiFlexGroup justifyContent="spaceBetween" alignItems="center">
              <EuiFlexItem grow={false}>
                <EuiText>
                  <h1>{languages.DEVICE_MANAGE}</h1>
                </EuiText>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiFlexGroup>
                  <EuiFlexItem style={{ width: 240 }}>
                    <div id="select_devs_file">
                      <EuiFilePicker
                        initialPromptText={languages.SELECT_DEVICE_FILE}
                        onChange={files => {
                          if (files.length === 0) {
                            setFileData(null);
                          } else {
                            setFileData(files[0]);
                          }
                        }}
                        display="default"
                      />
                    </div>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiButton
                      id="device_import"
                      isDisabled={!fileData}
                      onClick={() => {
                        const formData = new FormData();
                        formData.append('file', fileData);
                        importDevices({
                          headers: { 'Content-Type': 'multipart/form-data' },
                          data: formData,
                        });
                      }}
                    >
                      {languages.IMPORT}
                    </EuiButton>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
            </EuiFlexGroup>
            <Table
              style={{ marginTop: 16 }}
              items={devs}
              itemId="id"
              sortField="type_title"
              columns={[
                ...columns,
                {
                  field: 'operation',
                  name: languages.OPTION,
                  render: (value, record) => {
                    return (
                      <div>
                        {authority ? (
                          <EuiButtonEmpty
                            onClick={e => {
                              setId(record.id);
                              setName(record.name);
                              setKey('adaptor-productions-current');
                              setSelectValue('adaptor-productions-current');
                              setIsModalVisible(true);
                              setIsInvalid(false);
                              setOption('config');
                              allCheckedOnChange(true);
                              setOtherDevId(otherDevs.length > 0 ? otherDevs[0].value : '');
                              setSelected(otherDevs.length > 0 ? [otherDevs[0]] : []);
                            }}
                          >
                            {languages.CONFIG_MANAGE}
                          </EuiButtonEmpty>
                        ) : null}
                        <EuiButtonEmpty
                          onClick={() => {
                            setId(record.id);
                            setName(record.name);
                            setMaintain({
                              id: 33025,
                              start: moment(),
                              end: moment(),
                              sn: '',
                            });
                            setIsModalVisible(true);
                            setOption('maintain');
                          }}
                        >
                          {languages.REPAIR_MAINTENANCE}
                        </EuiButtonEmpty>
                        <EuiButtonEmpty
                          onClick={() => {
                            setDevId(record.id);
                            setMachName(record.name);
                            setIsModalVisible(true);
                            setOption('editDeviceName');
                          }}
                        >
                          {languages.MODIFY_DEVICE_NAME}
                        </EuiButtonEmpty>
                      </div>
                    );
                  },
                },
              ]}
            />
          </div>
        }
      />
      {modal}
      {isShowGuide}
      <Toast msg={toastMsg} />
    </div>
  );
}

render(Home, 'iiwsm');
