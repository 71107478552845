import { useEffect, useState } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useTokenTime } from '../data';

function useTimer(getWebSocket) {
  const [timeoutIndex, setTimeoutIndex] = useState(null);

  function clear() {
    clearTimeout(timeoutIndex);
  }

  function start() {
    setTimeoutIndex(
      setTimeout(function () {
        //console.log("120s内未收到服务器端消息，客户端主动断开链接！")
        getWebSocket().close();
      }, 120 * 1000)
    );
  }

  function reset() {
    //console.log("收到服务器端消息，清除心跳检测定时器")
    clear();
    start();
  }

  return [clear, start, reset];
}

function useWS({ domain, port, url, params, connect }) {
  if (!domain) domain = window.location.host.split(':')[0];
  let wsport = '';
  if (port) wsport = ':' + port;
  else if (window.location.port) wsport += ':' + window.location.port;
  const [{ refreshTime }] = useTokenTime();
  const socketUrl = `${
    window.location.protocol === 'http:' ? 'ws' : 'wss'
  }://${domain}${wsport}/api/${url}`;
  const [isActiveClose, setActiveCloseStatus] = useState(false);
  const [res, setRes] = useState({});
  const [reconnectTimeout, setReconnectTimeout] = useState(500);

  const { sendMessage, readyState, getWebSocket } = useWebSocket(
    socketUrl,
    {
      onOpen: () => {
        setReconnectTimeout(500);
        setActiveCloseStatus(false);
        start();
        if (params) sendMessage(JSON.stringify(params));
      },
      onClose: () => {
        //console.log('请求链接失败或断开！')
        clear();
        if (reconnectTimeout < 64000) setReconnectTimeout(reconnectTimeout * 2);
      },
      onMessage: evt => {
        reset();
        const result = JSON.parse(evt.data);
        if (result.rst === 0) sendMessage(evt.data);
        else if (result.rst === 401) window.location = 'login.html';
        else {
          setRes(result);
          //handleHttpMsg.change(res.rst, name)
        }
      },
      onError: evt => {
        console.log(evt.data);
      },
      shouldReconnect: () => !isActiveClose,
      reconnectAttempts: 60,
      reconnectInterval: reconnectTimeout,
    },
    connect
  );

  const [clear, start, reset] = useTimer(getWebSocket);

  const [tokenIsRefresh, setTokenIsRefresh] = useState(false);
  useEffect(() => {
    if (!tokenIsRefresh) setTokenIsRefresh(true);
    else {
      if (getWebSocket()) getWebSocket().close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTime]);

  const activeClose = function () {
    setActiveCloseStatus(true);
    getWebSocket().close();
  };

  const connectionStatus = {
    [ReadyState.CONNECTING]: '连接中',
    [ReadyState.OPEN]: '连接成功',
    [ReadyState.CLOSING]: '关闭连接中',
    [ReadyState.CLOSED]: '已关闭连接',
    [ReadyState.UNINSTANTIATED]: '不规范的连接',
  }[readyState];

  return {
    activeClose,
    ...res,
    getWebSocket,
    sendMessage,
    connectionStatus,
  };
}

export default useWS;
