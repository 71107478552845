import useRequest from '@/websdk/utils/request';

export default (function () {
  const { exec, isRequestSuccessed } = useRequest({
    url: '/api/materials',
    name: 'PUT_MATERIALS',
    method: 'PUT',
  });

  return [exec, isRequestSuccessed];
});
