import { useState, useEffect } from 'react';
import apis from './apis';
import useRequest from '../../utils/request';
import useStore from './store';

export default (function (loginId) {
  const [, dispatch] = useStore();
  const { data } = useRequest(apis.getUserAppRoleList);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    if (data) {
      let roles = [];
      for (let i = 0; i < data.length; i++) {
        if (loginId === data[i].user_id) {
          roles.push(data[i].role_name);
        }
      }
      setRoles(roles);
      dispatch.setLoginRoles(roles);
    }
  }, [data, dispatch, loginId]);

  return roles || [];
});
