const apis = {
  getLoginUser: {
    url: '/api/admin/customer/user',
    name: 'GET_LOGIN_USER',
  },
  getUserAppRoleList: {
    url: '/api/admin/user/app_role/list',
    name: 'GET_USER_APP_ROLE_LIST',
  },
  getRefreshToken: {
    url: '/api/refresh_token',
    name: 'REFRESH_TOKEN',
  },
  logOut: {
    url: '/api/admin/user/loginout',
    method: 'POST',
    name: 'LOG_OUT',
  },
};

export default apis;
