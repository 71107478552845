import { useEffect } from 'react';
import apis from './apis';
import useRequest from '../../utils/request';
import useStore from './store';

export default (function (loginUrl) {
  const [, dispatch] = useStore();
  const { exec, rst } = useRequest(apis.logOut);

  useEffect(() => {
    if (rst === 200) window.location = loginUrl;
    dispatch.setLoginUrl(loginUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rst, loginUrl]);

  return exec;
})
