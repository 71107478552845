import React, { useState, useEffect } from 'react';
import { EuiGlobalToastList } from '@elastic/eui';
import useHttpMsg from '../utils/http_msg.js';
import { getLang } from '../config/lang';
import locale from '../locale';

let toastId = 0;

function Toast() {
  const [toasts, setToasts] = useState([]);
  const [httpMsg] = useHttpMsg();
  const lang = getLang();

  useEffect(() => {
    if (httpMsg.rst !== 200) {
      const codeMsg = {
        400: locale[lang]['PARAMETER_ERROR'],
        403: locale[lang]['NO_PERMISSION'],
        404: locale[lang]['RESOURCE_NOT_FOUND'],
        500: locale[lang]['SERVER_ERROR'],
        502: locale[lang]['GATEWAY_ERROR'],
        503: locale[lang]['SERVICE_UNAVAILABLE'],
        504: locale[lang]['GATEWAY_TIMEOUT'],
      };
      setToasts(
        toasts.concat({
          id: (toastId++).toString(),
          title: `${httpMsg.name} ${locale[lang]['FAILED']}${locale[lang]['!']}`,
          text: `${locale[lang]['REASON']}${locale[lang][':']}${
            httpMsg.msg ? httpMsg.msg : codeMsg[httpMsg.rst]
          }`,
          color: 'danger',
          iconType: 'alert',
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [httpMsg]);

  const removeToast = removedToast => {
    setToasts(toasts.filter(toast => toast.id !== removedToast.id));
  };

  return <EuiGlobalToastList toasts={toasts} dismissToast={removeToast} toastLifeTimeMs={6000} />;
}

export default Toast;
